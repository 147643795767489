import React from "react"

import Playlist from "../components/playlist"

import imgbanner from "../images/index/thumb/lolrolls.jpg"
import allcategories from "../content/categorytitles.json"
import videolist from "../content/playlistlolrolls.json"


const LolrollsPage = ({location}) => (
  <Playlist
    location={location}
    headingClassName={"color-text-secondary"}
    path={"lolrolls"}
    bannerimg={imgbanner}
    title={allcategories.lolrolls.title}
    writeup={allcategories.lolrolls.description} 
    videolist={videolist} />
)

export default LolrollsPage
